import Header from './Header';
import Footer from './Footer';
import About from './About';
import NotFound from './NotFound';
import {Route,Routes,BrowserRouter as Router,useNavigate, Navigate} from 'react-router-dom';
import {useState,useEffect} from 'react'
import api from './api/login';
import LoginUI from './LoginUI';
import LogOutUI from './LogOutUI';
import ProtectedRoute from './ProtectedRoute';
import Spinner from 'react-bootstrap/Spinner';


import MoreOptions from './MoreOptions';
import StudentExamFormFillup from './StudentExamFormFillup';
import OfferedCourses from './OfferedCourses';
import NavAdmin from './NavAdmin';
import NavStudent from './NavStudent';
import UserNav from './UserNav';
import HomeAdmin from './HomeAdmin';
import HomeStudent from './HomeStudent';

function App() {    
  const [isAuthenticated,setIsAuthenticated]=useState(false); 
  const [isAppLoaded,setIsAppLoaded]=useState(true); 
  const [refreshFailed,setRefreshFailed]=useState(true);
  const [accessToken,setAccessToken]=useState(''); 
  const [loginType,setLoginType]=useState('');

  
  
  useEffect(()=>{
    const fetchAT=async ()=>{
          try{
            const config = { withCredentials: true };
            const x=await api.get('/getAccessToken',config);      
             
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            setAccessToken(nat);    
            setLoginType(lt);     
            setIsAuthenticated(true);   
            setRefreshFailed(false); 
            }
            catch(err)
            {
              //alert(err);
              setAccessToken('');   
              setLoginType('');   
              setIsAuthenticated(false);  
              setRefreshFailed(true);              
            }
            finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
    }
    fetchAT();
  },[refreshFailed]);
  
  return (   
    <Router>
      <div className="App">
      {<Header></Header>}
        { (isAppLoaded)&&!refreshFailed &&(loginType.toLowerCase()==="admin")&& <NavAdmin
          />}
         { (isAppLoaded)&&!refreshFailed &&(loginType.toLowerCase()==="student")&& <NavStudent
          />} 
          { (isAppLoaded)&&refreshFailed&& <UserNav
          />
          }

        { (isAppLoaded) &&!refreshFailed &&(loginType.toLowerCase()==="admin")&&
          <>
          <Routes>
             <Route element={<ProtectedRoute isAuthenticated={isAuthenticated}/>}>
                <Route  path='/' element={<HomeAdmin                        
                          
                          />}>            
                </Route>              
                <Route path='/moreoptions' element={<MoreOptions
                          />}> 
                </Route>
                <Route path='/offeredcourses' element={<OfferedCourses
                          accessToken={accessToken} 
                          setAccessToken={setAccessToken}
                          refreshFailed={refreshFailed}
                          setRefreshFailed={setRefreshFailed}
                          />}> 
                </Route>
            </Route>
         
        
            <Route  path='/about' element={<About></About>}></Route>
            <Route path='*' element={<NotFound></NotFound>}></Route>

            <Route  path='/login' element={!isAuthenticated? <LoginUI
                  setRefreshFailed={setRefreshFailed} setGlobalLoginType={setLoginType} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>:<Navigate to="/" />}>            
            </Route>
            <Route  path='/logout' element={isAuthenticated? <LogOutUI
            setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated} setLoginType={setLoginType} setRefreshFailed={setRefreshFailed}/>:<Navigate to="/Login" />}>            
            </Route>
          </Routes>
          </>
        }
      
      { (isAppLoaded) &&!refreshFailed &&(loginType.toLowerCase()==="student")&&
          <>
          <Routes>
             <Route element={<ProtectedRoute isAuthenticated={isAuthenticated}/>}>
                <Route  path='/' element={<HomeStudent 
                          />}>            
                </Route>              
                <Route path='/moreoptions' element={<MoreOptions
                          />}> 
                </Route>
                <Route path='/formfillup' element={<StudentExamFormFillup
                accessToken={accessToken} 
                setAccessToken={setAccessToken}
                refreshFailed={refreshFailed}
                setRefreshFailed={setRefreshFailed}
                          />}> 
                </Route>
            </Route>
         
        
            <Route  path='/about' element={<About></About>}></Route>
            <Route path='*' element={<NotFound></NotFound>}></Route>

            <Route  path='/login' element={!isAuthenticated? <LoginUI
                  setGlobalLoginType={setLoginType} setRefreshFailed={setRefreshFailed}  setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>:<Navigate to="/" />}>            
            </Route>
            <Route  path='/logout' element={isAuthenticated? <LogOutUI
            setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated} setRefreshFailed={setRefreshFailed}/>:<Navigate to="/Login" />}>            
            </Route>
          </Routes>
          </>
        }
      
      {
        !isAppLoaded &&
          <div className='spinnerovsetSeerlay'>
            <div className='spinnerbody'>
              <Spinner animation="border" variant="info" />
              <p>Please wait. It might take a few seconds.</p>
            </div>           
          </div>
      }
      {
       (isAppLoaded) && refreshFailed && 
        <>
        <Routes>
        <Route  path='/about' element={<About></About>}></Route>
          <Route path='*' element={ <LoginUI
                setGlobalLoginType={setLoginType} setRefreshFailed={setRefreshFailed} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>}>            
                </Route>

          <Route  path='/login' element={ <LoginUI
                setGlobalLoginType={setLoginType} setRefreshFailed={setRefreshFailed}   setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>}>            
          </Route>
          <Route  path='/logout' element={ <LogOutUI
          setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated} setRefreshFailed={setRefreshFailed}/>}>            
          </Route>
        </Routes>
        </>
      }

        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
