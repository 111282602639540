import React from 'react';
import CourseListS from './CourseListS';

const GroupS = ({ accessToken,setAccessToken,setRefreshFailed,group,slno,semester,session,selectedCourses,setSelectedCourses }) => {
  let classname='group  marginBottom';
  if(group.slno==1)
  {
    classname='group  marginBottom hcgrpback';
  }
  else if(group.slno==2)
    {
      classname='group  marginBottom hggrpback';
    }
    else if(group.slno==3)
      {
        classname='group  marginBottom hegrpback';
      }
      else if(group.slno==4)
        {
          classname='group  marginBottom segrpback';
        }
        else if(group.slno==5)
          {
            classname='group  marginBottom aegrpback';
          }
    return (
    <div className={classname}>
      <div className="groupRow">
        <div className='grpSlnoContainer'>{slno+"."}</div><div className='grpTitleContainer'>{group.title+" ("+"SEM "+semester+")"}</div>
      </div>
      <div className="groupRow">{group.selectinGuideline.length>0 && <p className='grpRuleContainer'> {group.selectinGuideline}</p>}</div>
      <div className="groupRow"><CourseListS accessToken={accessToken} 
                setAccessToken={setAccessToken}
                setRefreshFailed={setRefreshFailed} semester={semester} session={session} setSelectedCourses={setSelectedCourses} courses={group.courses} selectedCourses={selectedCourses} groupslno={group.slno} /></div>
    </div>
  );
};

export default GroupS;
