import React from 'react';
import GroupT from './GroupT';

const OfferedCoursesT = ({ data }) => {

  let x=``;
  if(data && data.offeredCourses && data.offeredCourses.groups && data.offeredCourses.groups.length>0)
  {
    x= <div className='verticaldiv centrediv width1000 marginTopm'>
    {data.offeredCourses.groups.map((group,index) => (
      <GroupT key={group.id} group={group} slno={index+1} />
    ))}
    </div>
  }
  else{
    x='No GROUPS to display!';
  }

  return (
   x
  );

};

export default OfferedCoursesT;
