import React from 'react';
import CourseItemS from './CourseItemS';

const CourseListS = ({ accessToken,setAccessToken,setRefreshFailed,courses,groupslno,selectedCourses,setSelectedCourses,semester,session }) => {
  return (
    <div className='verticaldiv centrediv fullWidth'>
      {courses.map((course,index) => (
        <CourseItemS accessToken={accessToken} 
        setAccessToken={setAccessToken}
        setRefreshFailed={setRefreshFailed} semester={semester} session={session} setSelectedCourses={setSelectedCourses} selectedCourses={selectedCourses} key={course.code} course={course} slno={index+1} gslno={groupslno} />
      ))}
    </div>
  );
};

export default CourseListS;
