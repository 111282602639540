
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import loginapi from './api/login';
import OfferedCoursesT from './OfferedCoursesT';



function OfferedCourses({accessToken,setAccessToken,setRefreshFailed}) {
  const [selectedProgram,setSelectedProgram]=useState('');
  const [selectedSpecialisation,setSelectedSpecialisation]=useState('');
  const [selectedSemester,setSelectedSemester]=useState(-1);
  
  const [isAppLoaded,setIsAppLoaded]=useState(false);
  const [programs,setPrograms]=useState([]);
  const [semOfferedCourses,setSemOfferedCourses]=useState(null);

  const loadOfferedCourses=async ()=>{

    try{       
      //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
      const response=await api.post('/getOfferedCourses',{program:selectedProgram,specialisation:selectedSpecialisation,semester:selectedSemester},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
      //alert("load courses "+JSON.stringify(response.data));
      setSemOfferedCourses(response.data);
      //alert(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await loginapi.get('/getAccessToken',config);            
          const nat=x['data']['accessToken'];
          const ufn=x['data']['userFullName'];
          const un=x['data']['userName'];
          const lt=x['data']['loginType'];
          //alert(nat);
          setAccessToken(nat);
          setRefreshFailed(false);  
                  
         
            if(lt.toLowerCase()==="admin")
              {
                    try{       
                      //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                      const response=await api.post('/getOfferedCourses',{program:selectedProgram,specialisation:selectedSpecialisation,semester:selectedSemester},{headers:{'Authorization':`Bearer ${nat}`}});                            
                      //alert("load courses "+JSON.stringify(response.data));
                      setSemOfferedCourses(response.data);
                      //alert(response.data);
                  }
                  catch(err)
                  {
                    alert('refresh failed unusual'+JSON.stringify(err));
                    setAccessToken('');
                    setRefreshFailed(true);  
                  }
                }
          }
          catch(err)
          {
            //alert('dd');
            setAccessToken(''); 
            setRefreshFailed(true);              
          }
      }         
       finally
          {
            setIsAppLoaded(true);
            //alert('app loaded'+accessToken);
          }
  }

  useEffect(()=>{
    const fetchAT=async ()=>{

      try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await api.post('/getAllPrograms',{id:1},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        //alert(JSON.stringify(response.data));
        setPrograms(response.data);
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="admin")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await api.post('/getAllPrograms',{id:1},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify(response.data));
                        setPrograms(response.data);
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      alert('refresh failed unusual'+JSON.stringify(err));
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
    }
    fetchAT();
  },[]);
  
  useEffect(()=>{
    loadOfferedCourses();
  },[selectedSemester]);
  // Handle dropdown change event
  function onProgChange(e)
  {
    setSelectedProgram(e.target.value);
    setSelectedSpecialisation('');
    setSelectedSemester(-1);
  }
  // Handle dropdown change event
  const onSpecialisationChange = (event) => {
    setSelectedSpecialisation(event.target.value);
    setSelectedSemester(-1);
  };
  const onSemClick = async (event) => {
    setSelectedSemester(event.target.value);
  };
  
  function getDDLProgJSX()
  {
    return (<select className="borderLess" value={selectedProgram} id='ddlProgram'
      onChange={(e)=>{onProgChange(e)}}
            >
      <option key={'none'} value={''}>
            {'SELECT ONE'}
      </option>
      {programs.map((program) => (
          <option key={program.code} value={program.code}>
            {program.title}
          </option>
        ))}
      </select>);
  }

  function getDDLSpecialisationJSX()
  {
     // Find the program by code
  const px = programs.find((program) => program.code === selectedProgram);
  
  
    return (<select className="borderLess" value={selectedSpecialisation} id='ddlSpecialisation'
      onChange={(e)=>{onSpecialisationChange(e)}}
            >
      <option key={'none'} value={''}>
            {'SELECT ONE'}
      </option>
      {px && px.specialisation.map((s) => (
          <option key={s} value={s}>
            {s}
          </option>
        ))}
      </select>);
  }

  function getSelectSemesterPanel()
  {
    const noofsem=8;
    const semesters =Array.from({ length: noofsem }, (_, index) => ({
      id: index + 1,
      text: `sem${index + 1}`
    }));
    return (
      <div className="semcontainer marginTopm">
        <p>Please select a Semester</p>
        <div className="album">
          {semesters.map(item => (
            <button
              value={item.id}
              key={item.id}
              className={ item.id==selectedSemester?'circlebutton circularbuttonactive':'circlebutton circularbuttoninactive'}
              onClick={(e)=>{onSemClick(e)}}
            >
              {item.text}
            </button>
          ))}
        </div>
      </div>
    );
  }
  function getOfferedCourses()
  {
    return (
      <div className="verticaldiv centrediv fullWidth marginTopm">
         <OfferedCoursesT data={semOfferedCourses} />
      </div>
    );
  }

  
    return (
      <main>
        <div className="fullWidth centeredDiv"><h3>SET OFFERED COURSES</h3></div>
        <label className="marginTop"  htmlFor='ddlProgram'>SELECT PROGRAM:</label>
        {getDDLProgJSX()}

        <label className="marginTop"  htmlFor='ddlSpecialisation'>SELECT SPECIALISATION:</label>
        {getDDLSpecialisationJSX()}
        
        {selectedProgram!='' && selectedSpecialisation!='' && getSelectSemesterPanel()}
        {selectedProgram!='' && selectedSpecialisation!='' && selectedSemester>0 && getOfferedCourses()}
        
       
      </main>
    )
  }
  
  export default OfferedCourses