import React, { useState } from 'react';
const SelectedCourseItem = ({slno,course}) => {
  
  return (
    <div className='offeredcourseitemwithslno marginTopSmall'>
        <div className='offeredcourseitemslnocontainer'>
            <div className='offeredcourseitemslno'>
              {slno}
            </div>
        </div>
        <div className='OfferedCourseItem '>
            <div className='OfferedCourseItemR1'>
                <div className='OfferedCourseItemR1C1'>
                    <div className='OfferedCourseItemCodeCover'>
                            <label className='OfferedCourseItemCodeLabel'>CODE</label>
                            <label className='OfferedCourseItemCodeValue'>{course.code}</label>
                    </div>
                </div>
                <div className='OfferedCourseItemR1C2'>
                        <div className='OfferedCourseItemActionCover'>
                            
                        </div>
                </div>
            </div>
            <div className='OfferedCourseItemR2'>
                    <div className='OfferedCourseItemTitleCover'>
                            <label className='OfferedCourseItemCodeLabel'>TITLE</label>
                            <label className='OfferedCourseItemCodeValue'>{course.title}</label>
                    </div>
            </div>
            <div className='OfferedCourseItemR3'>
                    <div className='OfferedCourseItemIPECrCover'>
                            <div className='OfferedCourseItemIPECrForType'>
                                <label className='OfferedCourseItemCodeLabel'>TYPE</label>
                                <label className='OfferedCourseItemCodeValue'>{course.type}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>CRDT</label>
                                <label className='OfferedCourseItemCodeValue'>{course.credit}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>I</label>
                                <label className='OfferedCourseItemCodeValue'>{course.i}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>P</label>
                                <label className='OfferedCourseItemCodeValue'>{course.p}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>E</label>
                                <label className='OfferedCourseItemCodeValue'>{course.e}</label>
                            </div>                            
                            
                    </div>
            </div>
        </div>
    </div>
  );
};

export default SelectedCourseItem;
