import {useEffect,useState} from 'react';
import api from './api/login';
import {Navigate} from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';

function LogOutUI({setRefreshFailed,setAccessToken,setIsAuthenticated}) {
   const [isLoading,setIsloading]=useState(true);
   useEffect(()=>{
    const fetchAT=async ()=>{
      try{
        const config = { withCredentials: true };
        const response= await api.post('/logout',{},config);      
        setRefreshFailed(true);
        setAccessToken('');
        setIsAuthenticated(false);
        }
        catch(err)
        {
          //setRefreshFailed(false);
          setRefreshFailed(true);
          setAccessToken('');
          setIsAuthenticated(false);
        }
        finally{setIsloading(false)}
      }
      fetchAT();
  },[])

      return (
        <main>    
          {isLoading && 
        <div className='spinneroverlay'>
          <div className='spinnerbody'>
            <Spinner animation="border" variant="info" />
            <p>Please wait. It might take a few seconds.</p>
          </div>           
        </div>}

          {!isLoading&&<Navigate to="/login"></Navigate>}
        </main>
       
      )
    }
    
export default LogOutUI
