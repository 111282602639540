import React from 'react';
import CourseItemT from './CourseItemT';

const CourseListT = ({ courses,groupslno }) => {
  return (
    <div className='verticaldiv centrediv fullWidth'>
      {courses.map((course,index) => (
        <CourseItemT key={course.code} course={course} slno={index+1} gslno={groupslno} />
      ))}
    </div>
  );
};

export default CourseListT;
