import React, { useState }  from 'react';
import api from './api/posts';
import loginapi from './api/login';


const CourseItemS = ({accessToken,setAccessToken,setRefreshFailed, course,slno,gslno,selectedCourses,setSelectedCourses,semester,session}) => {
  const [isAppLoaded,setIsAppLoaded]=useState(true);
  const updateSelectedCourses=async ()=>{
    //alert(JSON.stringify(loggedInStudent)+" selected sem "+selectedSemester);
     try{       
       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
       const response=await api.post('/updateSemesterSelectedCourses',{semester:semester,session:session,code:course.code,type:course.type},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
       //alert(JSON.stringify( response.data)); 
       setSelectedCourses(response.data.data);
       //alert(response.data);
       }
       catch(err)
       {
         try{
           const config = { withCredentials: true };
           const x=await loginapi.get('/getAccessToken',config);            
           const nat=x['data']['accessToken'];
           const ufn=x['data']['userFullName'];
           const un=x['data']['userName'];
           const lt=x['data']['loginType'];
           //alert(nat);
           setAccessToken(nat);
           setRefreshFailed(false);  
                   
          
             if(lt.toLowerCase()==="student")
               {
                     try{       
                       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                       const response=await api.post('/updateSemesterSelectedCourses',{semester:semester,session:session,code:course.code,type:course.type},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify( response.data)); 
                        setSelectedCourses(response.data.data);
                       //alert(response.data);
                   }
                   catch(err)
                   {
                     alert('refresh failed unusual'+ "line 50");
                     setAccessToken('');
                     setRefreshFailed(true);  
                   }
                 }
           }
           catch(err)
           {
             //alert('dd');
             setAccessToken(''); 
             setRefreshFailed(true);              
           }
       }         
        finally
           {
             setIsAppLoaded(true);
             //alert('app loaded'+accessToken);
           }
  }


  const handleCheckChange = async () => {
    await updateSelectedCourses();
  };



  let classname='offeredcourseitemwithslno marginTopSmall';
  if(gslno==1)
  {
    classname='offeredcourseitemwithslno marginTopSmall hcback';
  }
  else if(gslno==2)
    {
      classname='offeredcourseitemwithslno marginTopSmall hgback';
    }
    else if(gslno==3)
      {
        classname='offeredcourseitemwithslno marginTopSmall heback';
      }
      else if(gslno==4)
        {
          classname='offeredcourseitemwithslno marginTopSmall seback';
        }
        else if(gslno==5)
          {
            classname='offeredcourseitemwithslno marginTopSmall aeback';
          }
 let codeExists =selectedCourses && selectedCourses.courses.some(coursex => coursex.code === course.code);
 if(codeExists)
  {
    classname='offeredcourseitemwithslno marginTopSmall selectedback';
  }
 
  return (
    <div className={classname}>
        <div className='offeredcourseitemslnocontainer'>
            <div className='offeredcourseitemslno'>
              {slno}
            </div>
        </div>
        <div className='OfferedCourseItem'>
            <div className='OfferedCourseItemR1'>
                <div className='OfferedCourseItemR1C1'>
                    <div className='OfferedCourseItemCodeCover'>
                            <label className='OfferedCourseItemCodeLabel'>CODE</label>
                            <label className='OfferedCourseItemCodeValue'>{course.code}</label>
                    </div>
                </div>
                <div className='OfferedCourseItemR1C2'>
                        <div className='OfferedCourseItemActionCover'>
                        <input
                            type="checkbox"
                            checked={codeExists}
                            onChange={handleCheckChange}
                            style={{width: "25px", height: "25px", marginRight: "3px" }}
                          />
                        </div>
                </div>
            </div>
            <div className='OfferedCourseItemR2'>
                    <div className='OfferedCourseItemTitleCover'>
                            <label className='OfferedCourseItemCodeLabel'>TITLE</label>
                            <label className='OfferedCourseItemCodeValue'>{course.title}</label>
                    </div>
            </div>
            <div className='OfferedCourseItemR3'>
                    <div className='OfferedCourseItemIPECrCover'>
                            <div className='OfferedCourseItemIPECrForType'>
                                <label className='OfferedCourseItemCodeLabel'>TYPE</label>
                                <label className='OfferedCourseItemCodeValue'>{course.type}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>CRDT</label>
                                <label className='OfferedCourseItemCodeValue'>{course.credit}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>I</label>
                                <label className='OfferedCourseItemCodeValue'>{course.internal}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>P</label>
                                <label className='OfferedCourseItemCodeValue'>{course.practical}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>E</label>
                                <label className='OfferedCourseItemCodeValue'>{course.external}</label>
                            </div>                            
                            
                    </div>
            </div>
        </div>
    </div>
  );
};

export default CourseItemS;
