import { useEffect,useState } from "react";
import { Link } from 'react-router-dom';
function HomeStudent({userFullName}) {

  return (
    <main>
      <article className='newPost'>
      <div className="fullWidth">
      <p className="text text-info baseddDiv">{userFullName}</p>
          <div className="fullWidth featureContainer ">
                <button className="featureButton"><Link className='navLinkBlack' to="/formfillup">FORM FILLUP</Link></button> 
                <button className="featureButton"><Link className='navLinkBlack' to="/formfillupx">UPLOAD RECEIPT</Link></button> 
                <button className="featureButton"><Link className='navLinkBlack' to="/formfillupx">VIEW RESULT</Link></button> 
                <button className="featureButton"><Link className='navLinkBlack' to="/formfillupx">ADMIT CARD</Link></button> 
          </div>
      </div>
      </article>
    </main>
    )
  }
  
  export default HomeStudent