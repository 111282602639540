function About() {
    return (
      <main>
        <div className="aboutDiv">
          <div className="section">
            <h4 className="sectionhead uppercase mainhead scrollanim">
               ABOUT US
            </h4>
            <div className="sectionbody sectionbodytext scrollanim">
            Welcome to the Bhattadev University Student Information Management System! Seamlessly connecting students, faculty, and administration for a smoother academic experience.

            </div>
          </div>

          <div className="section">
            
            <div className="sectionbody yrostext">
           
            Bhattadev University Student Management System is developed and maintained by YROS Software, Guwahati,Assam. For inquiries about new software development projects, including AI-based applications, website development, web app development, or mobile app development, please contact us at 789-692-2693 or email contactyros@gmail.com.

            </div>
          </div>


        </div>
      </main>
    )
  }
  
  export default About