
import axios from './api/login';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react'

import Spinner from 'react-bootstrap/Spinner';

function LoginUI({setAccessToken,setIsAuthenticated,setRefreshFailed,setGlobalLoginType}){
  const [userName,setUserName]=useState('');
  const [password,setPassword]=useState('');
  const navigate=useNavigate();
  const [isLoading,setIsloading]=useState(false);

  const [loginType,setLoginType]=useState('student');

  const handleLoginTypeChange = (event) => {
    setLoginType(event.target.value);
  };

  async function handleLogin(e)
    {
      e.preventDefault();
      setIsloading(true);
      try{
            const config = { withCredentials: true };
            const data=await axios.post('/login',{
            userName:userName,
            password:password, 
            loginType:loginType           
            },config);
            //alert(JSON.stringify(data['data']['accesstoken']));
            setAccessToken(data['data']['accesstoken']);
            setGlobalLoginType(data['data']['loginType']);
            setIsAuthenticated(true);
            setRefreshFailed(false);
            setUserName('');
            setPassword('');
            navigate('/');              
                       
      }
      catch(err)
      {
        //alert(err);
        setAccessToken('');
        setIsAuthenticated(false);
        setGlobalLoginType('');
        setRefreshFailed(true);
        alert('Login failed!');
      }
      finally
      {
        setIsloading(false);     
      }
    }

    return (  
      <main>
        <form className="loginForm">
          <div className='loginTypeDiv'>
                <label className='radiolabel'>
                  <input
                    type="radio"
                    value="student"
                    checked={loginType === 'student'}
                    onChange={handleLoginTypeChange}
                  />
                  <div className={loginType === 'student' ? 'radiooptiondiv selectedloginoption' : 'radiooptiondiv'}>STUDENT</div>
                </label>
                <label className='radiolabel'>
                  <input
                    type="radio"
                    value="admin"
                    checked={loginType === 'admin'}
                    onChange={handleLoginTypeChange}
                  />
                   <div className={loginType === 'admin' ? 'radiooptiondiv selectedloginoption' : 'radiooptiondiv'}>ADMIN</div>
                </label>
          </div>
          
          {loginType === 'admin' && <div className="loginDiv marginTopm">
            <label>USER NAME:</label>
            <input className="borderLess transparent" type='text'  onChange={(e)=>{setUserName(e.target.value)}} value={userName}></input>
            <label className="marginTop">PASSWORD</label>
            <input className="borderLess transparent" type='password' onChange={(e)=>{setPassword(e.target.value)}} value={password}></input>
            <div className="marginTop fullWidth loginButtonyNav"><button className="btn btn-primary loginButton" name='btnLogin' onClick={handleLogin}>ADMIN LOGIN</button></div>
          </div>}
          {loginType === 'student' && <div className="loginDiv marginTopm">
            <label>ROLL NO:</label>
            <input className="borderLess transparent" type='text' placeholder="type your roll number" onChange={(e)=>{setUserName(e.target.value)}} value={userName}></input>
            <label className="marginTop">PASSWORD</label>
            <input className="borderLess transparent" type='password' placeholder="See hint below" onChange={(e)=>{setPassword(e.target.value)}} value={password}></input>
            <div className="marginTop fullWidth loginButtonyNav"><button className="btn btn-primary loginButton" name='btnLogin' onClick={handleLogin}>STUDENT LOGIN</button></div>
            <div className="marginTop fullWidth"><p className='parapasswordhint'>Password Hint: your rollno followed by your name in CAPITAL LETTER. No Space. For ex. if your rollno is <span>UA190013</span> and Name is <span>Pallabi Bora</span>, your password will be <span>UA190013PALLABIBORA</span> </p></div>
          </div>}
        </form>

        {isLoading && 
        <div className='spinneroverlay'>
          <div className='spinnerbody'>
            <Spinner animation="border" variant="info" />
            <p>Please wait. It might take a few seconds.</p>
          </div>           
        </div>}
      </main> 

       );
}
export default LoginUI;
