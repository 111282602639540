import {Link} from 'react-router-dom'
function NotFound() {
    return (
      <main>
        <p>
            Oops!! 404 page not found! Please visit <Link to='/'>our homepage</Link>
        </p> 
      </main>
    )
  }
  
  export default NotFound