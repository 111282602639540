import React from 'react';
const onRemoveClick=(e)=>{alert('remove clicked!');}
const CourseItemT = ({ course,slno,gslno }) => {
  let classname='offeredcourseitemwithslno marginTopSmall';
  if(gslno==1)
  {
    classname='offeredcourseitemwithslno marginTopSmall hcback';
  }
  else if(gslno==2)
    {
      classname='offeredcourseitemwithslno marginTopSmall hgback';
    }
    else if(gslno==3)
      {
        classname='offeredcourseitemwithslno marginTopSmall heback';
      }
      else if(gslno==4)
        {
          classname='offeredcourseitemwithslno marginTopSmall seback';
        }
        else if(gslno==5)
          {
            classname='offeredcourseitemwithslno marginTopSmall aeback';
          }

  return (
    <div className={classname}>
        <div className='offeredcourseitemslnocontainer'>
            <div className='offeredcourseitemslno'>
              {slno}
            </div>
        </div>
        <div className='OfferedCourseItem'>
            <div className='OfferedCourseItemR1'>
                <div className='OfferedCourseItemR1C1'>
                    <div className='OfferedCourseItemCodeCover'>
                            <label className='OfferedCourseItemCodeLabel'>CODE</label>
                            <label className='OfferedCourseItemCodeValue'>{course.code}</label>
                    </div>
                </div>
                <div className='OfferedCourseItemR1C2'>
                        <div className='OfferedCourseItemActionCover'>
                            <button className='btn btn-danger btntext' onClick={(e)=>{onRemoveClick(e)}}>REMOVE</button>
                        </div>
                </div>
            </div>
            <div className='OfferedCourseItemR2'>
                    <div className='OfferedCourseItemTitleCover'>
                            <label className='OfferedCourseItemCodeLabel'>TITLE</label>
                            <label className='OfferedCourseItemCodeValue'>{course.title}</label>
                    </div>
            </div>
            <div className='OfferedCourseItemR3'>
                    <div className='OfferedCourseItemIPECrCover'>
                            <div className='OfferedCourseItemIPECrForType'>
                                <label className='OfferedCourseItemCodeLabel'>TYPE</label>
                                <label className='OfferedCourseItemCodeValue'>{course.type}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>CRDT</label>
                                <label className='OfferedCourseItemCodeValue'>{course.credit}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>I</label>
                                <label className='OfferedCourseItemCodeValue'>{course.internal}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>P</label>
                                <label className='OfferedCourseItemCodeValue'>{course.practical}</label>
                            </div>
                            <div className='OfferedCourseItemIPECr'>
                                <label className='OfferedCourseItemCodeLabel'>E</label>
                                <label className='OfferedCourseItemCodeValue'>{course.external}</label>
                            </div>                            
                            
                    </div>
            </div>
        </div>
    </div>
  );
};

export default CourseItemT;
